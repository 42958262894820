/* globals Modernizr */

'use strict';

Modernizr.addTest('ms', function () {
    return !!window.MSStream;
});

Modernizr.addTest('ipad', function () {
    return !!navigator.userAgent.match(/iPad/i) && !Modernizr.ms;
});

Modernizr.addTest('iphone', function () {
    return !!navigator.userAgent.match(/iPhone/i) && !Modernizr.ms;
});

Modernizr.addTest('ipod', function () {
    return !!navigator.userAgent.match(/iPod/i) && !Modernizr.ms;
});

Modernizr.addTest('ios', function () {
    return (Modernizr.ipad || Modernizr.ipod || Modernizr.iphone);
});

Modernizr.addTest('safari', function () {
    return !Modernizr.ms && navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i);
});

Modernizr.addTest('firefox', function () {
    return !Modernizr.ms && !!navigator.userAgent.match(/firefox/i);
});

Modernizr.addTest('chrome', function () {

    // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618

    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== 'undefined';
    var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');
    
    if (isIOSChrome) {
        // is Google Chrome on IOS
    }
    else if(
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
        // is Google Chrome
        return true;
    }
    else { 
        // not Google Chrome 
    }

    return false;
});
